import '/assets/styles/pages/checkout-summary.scss';
import { useEffect, useState } from 'react';

// Functions
import formatDate from 'pstv-commerce-tools/functions/format-date';

// Context
import { useParams } from 'pstv-commerce-tools/utilities/navigator'
import { useGlobalState } from 'pstv-commerce-tools/utilities/global-state';

// Partials
import Loader from '/views/partials/loader';
import Btn from '/views/partials/btn';
import Img from 'pstv-commerce-tools/partials/img';

// Services
import userServices from '/services/user'

// Static Assets
import image_complete_check from '/assets/images/modals/auth/complete-check.svg'

const CheckoutSummary = () => {
	const { id: saleID, code: saleCode } = useParams('checkoutSummary');
	const { setNotFound } = useGlobalState();

	const [data, setData] = useState(false);

	useEffect(() => {
		if(saleID && saleCode) {
			userServices.getSaleSummary(saleID, saleCode).then((fetchedData) => {
				setData({
					...fetchedData,
					deliveryDateStr: formatDate(fetchedData.sale.delivery_date, 'd MMMM', { checkRelative: true }),
				});
			}).catch(() => {
				setNotFound();
			})
		}
	}, [saleID, saleCode])

	return (
		<>
			<header className="checkout-header">
				<div className="wrapper">
					<h1 className="header-title">Sipariş Özeti</h1>
				</div>
			</header>
			<div className="wrapper summary-wrap loader-container">
				<Loader solid loading={!data} message="Siparişiniz getiriliyor..." />
				{data &&
					<div className="summary-container">
						<Img src={image_complete_check} className="complete-check" />
						<h2 className="summary-title">Siparişiniz Alındı</h2>
						<div className="summary-delivery">
							<strong>{data.delivery.delivery_address}</strong> adresine <br />
							{data.deliveryDateStr} {data.sale.delivery_hours} saatleri arasında teslim edilecektir.
						</div>
						<div className="summary-orderid">Sipariş No: {data.sale.sale_code}</div>
						<div className="summary-controls">
							<Btn className="primary wide" tag="link" href="home">Anasayfaya Git</Btn>
							<Btn className="secondary wide" tag="link" href="account.orders">Siparişlerime Git</Btn>
						</div>
					</div>
				}
			</div>
		</>
	)
}

// const CheckoutOptions = () => {
// 	return (
// 		<div className="main-section checkout-options">
// 			<strong className="section-title">Sipariş Tercihleri</strong>
// 			<span className="section-subtitle">Sipariş verdiğiniz ürünün mağazamızdaki stokta bulunmadığı durum için tercihinizi yapınız.</span>
// 			<div className="payment-content">
// 				...
// 			</div>
// 		</div>
// 	)
// }

export default CheckoutSummary
